<template>
  <div class="w-100 df-ac-js flex-wrap">
    <div class="guessyou-goods-item" v-for="(item,index) in goodsList" :key="index"
         @click="toProductDetail(item)">
      <img v-lazy="item.slide_list.img" style="width: 220px;height: 220px;" class="my mx"/>
      <div class="mx-1 mt font-s-2 text-grey in1line">{{ item.title }}</div>
      <div class="category-price text-main-color ml-1 mt-1 font-s-3">￥{{ item.price }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IndexLikeGoods',
  props:{
    goodsList:{
      type:Array,
      default(){
        return []
      }
    }
  },
  methods: {
    // 跳转到商品详情页
    toProductDetail (productInfo) {
      console.log(productInfo)
      this.$router.push({ path: '/product', query: { productId: productInfo.id } })
    }
  },
}
</script>

<style scoped>
.guessyou-goods-item{
  cursor: pointer;
}

.guessyou-goods-item {
  width: 230px;
  height: 322px;
  margin-right: 5px;
  margin-bottom: 10px;
  background: #ffffff;
}
.guessyou-goods-item:hover{
  box-shadow:0px 0px 15px rgb(159, 159, 159);
}
</style>
