<template>
  <div>
    <!-- fixed-top固定头部的CSS代码 -->
    <div class="w-100 fixed-top" :class="topNavStatus ? 'top-nav-show':'top-nav'"
         style="border-bottom: 1px red solid;">
      <div class="w-1200 df-ac-js" style="height: 60px;">
        <div class="df-ac" style="height: 60px;">
          <router-link class="df-ac" to="/">
            <div class="mr-8">
              <img :src="shopAppInfo.pc_logo" style="width: 180px;height: 51px;"/>
            </div>
          </router-link>
          <div class="df-ac ml-9" style="">
            <div class=""><input class="search-input" v-model="keyword" @keyup.enter="searchGoods"
                                 placeholder="请输入商品名称"/></div>
            <div class="search-click bg-main-color df-ac-jc font-s-3" @click="searchGoods">搜商品</div>
          </div>
        </div>
        <div class="my-shopping-cart font-s-1 f-r df-ac-jc border text-main-color" @click="toCart">
          <div class="iconfont icon-gouwuche mr font-s-7"></div>
          <div class="df-ac">
            我的购物车
            <div class="bg-main-color shopping-num border-radius-50 df-ac-jc font-s-1" v-if="cartNum>0">{{cartNum}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 固定头部结束 -->
    <div class="w-100 " style="background-color: #EAEAEA;">
      <div class="w-1200 df-ac-js" style="height: 30px;">
        <div class="df-ac font-s-1">
          <div class="mr-2 d-f align-center text-grey">
            <div class="iconfont icon-dizhi text-main-color"></div>
            上海
          </div>
          <router-link to="/userCenter" v-if="loginStatus">
              <el-popover
                placement="bottom"
                width="120"
                trigger="hover">
                <el-button size="medium" @click="logout" type="danger"
                           style="display: block;margin: 10px auto">退出登录
                </el-button>
                <div slot="reference" class="mr-2 text-main-color">{{userName}}</div>
              </el-popover>

          </router-link>
          <router-link to="/login" v-else-if="!loginStatus">
            <div class="mr-2 text-main-color">请登陆</div>
          </router-link>
        </div>
        <div class="df-ac font-s-1 text-grey">
          <router-link to="/userCenter/order">
            <div class="mr-2">我的订单</div>
          </router-link>

          <div class="mr-2">我的浏览</div>
          <div class="mr-2">我的收藏</div>
          <div class="mr-2">客户服务</div>
          <div>网站导航</div>
        </div>
      </div>
    </div>
    <div class="w-1200 df-ac-js" style="height: 100px;">
      <div class="logo f-l df-ac">
        <div style="width: 280px;height: 120px;">
          <router-link class="df-ac" to="/">
            <div style="width: 240px;height: 68px;display: table-cell;vertical-align: middle;">
              <img class="mt-3" :src="shopAppInfo.pc_logo">
            </div>
          </router-link>
        </div>
        <div class="ml-8 mt-1">
          <div class="df-ac" style="">
            <div class=""><input class="search-input" v-model="keyword" @keyup.enter="searchGoods"
                                 placeholder="请输入商品名称"/></div>
            <div class="search-click bg-main-color df-ac-jc font-s-3" @click="searchGoods">搜商品</div>
          </div>
          <div class="df-ac font-s-1 font-w-500 mt text-grey">
            <div class="mr-1 font-w-500">钻石</div>
            <div class="mr-1 font-w-500">手机</div>
            <div class="mr-1 font-w-500">王老K</div>
          </div>
        </div>
      </div>
      <div class="my-shopping-cart font-s-1 f-r df-ac-jc border text-main-color" @click="toCart">
        <div class="iconfont icon-gouwuche mr font-s-7"></div>
        <div class="df-ac">
          我的购物车
          <div class="bg-main-color shopping-num border-radius-50 df-ac-jc font-s-1" v-if="cartNum>0">{{cartNum}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userInfo as getUserInfoUrl,getConfigUrl,logout as logoutUrl} from '@/api/index'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      userName: '',
      keyword: '',
      topNavStatus: false,
      shopAppInfo:{},
      loginStatus:false,
    }
  },
  created () {
    this.initData()
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    // 页面滚动事件
    handleScroll (e) {
      try {
        if(e.target.documentElement != undefined){
          let scrollTop = e.target.documentElement.scrollTop
          // 执行代码
          this.topNavStatus = scrollTop >= 100;
        }
      }catch (e) {
        console.log(e)
      }
    },
    // 登录状态
    initData () {
      this.getUserInfo()
      this.getShopAppInfo()
      this.getCartList()
    },

    // 获取系统信息
    async getShopAppInfo(){
      this.shopAppInfo = this.$store.state.shopAppInfo
      if(JSON.stringify(this.shopAppInfo) == '{}'){
        // 因为会在后台自动配置，随时会修改，不能做缓存所以直接放到vuex中
        const{data:res} = await this.$http.get(getConfigUrl);
        if (res.code !== 200) return this.$message.error(res.msg)
        this.shopAppInfo = res.data
        this.$store.commit('changeShopAppInfoMutations',this.shopAppInfo)
      }
    },
    // 获取用户信息
    async getUserInfo () {
      // 先从vuex中判断是否登录
      let token = window.localStorage.getItem('token')
      let userInfo = window.sessionStorage.getItem('userInfo')
      this.loginStatus = this.$store.state.loginStatus
      if (!this.loginStatus) {
        // 再判断缓存中有没有token和用户信息
        if (token == '' || token == null) {
          // token为空清除userInfo的缓存
          window.sessionStorage.removeItem('userInfo')
          this.$store.commit('changeLoginStatusMutations', false)
          return false
        } else {
          this.loginStatus = true
          this.$store.commit('changeLoginStatusMutations', true)
        }
        if (userInfo == '' || userInfo == null) {
          const { data: userInfoRes } = await this.$http.get(getUserInfoUrl)
          if (userInfoRes.code !== 200) return this.$message.error(userInfoRes.msg)
          window.sessionStorage.setItem('userInfo', JSON.stringify(userInfoRes.data))
          this.userName = userInfoRes.data.name
        } else {
          this.userName = JSON.parse(userInfo).name
          this.$store.commit('changeLoginStatusMutations', true)
        }
      } else {
        this.userName = JSON.parse(userInfo).name
      }
    },
    // 获取购物车数据
    async getCartList(){
      let token = window.localStorage.getItem('token')
      if (token == '' || token == null) {
        return false
      }
      await this.$store.dispatch('changeCartNumActions')
    },
    // 跳转到商品搜索页
    searchGoods () {
      if (this.$route.query.keyword !== undefined) {
        if (this.$route.query.keyword === this.keyword) {
          return false
        }
      }
      this.$router.push({ path: '/searchGoods', query: { keyword: this.keyword } })
      this.keyword = ''
    },
    // 跳转到购物车
    toCart () {
      let token = window.localStorage.getItem('token')
      if (token == '' || token == null) {
        this.$router.push('/login')
        return false
      }
      this.$router.push({ path: '/cart' })
    },
    // 退出登录
    logout(){
      this.$confirm('您确定要退出登录吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.get(logoutUrl)
        if (res.code !== 200) return this.$message.error('退出登录失败' + res.msg)
        window.sessionStorage.removeItem('userInfo')
        window.sessionStorage.removeItem('distributionUserInfo')
        window.sessionStorage.removeItem('distributeUserCenterInfo')
        window.localStorage.removeItem('token')
        this.loginStatus = false
        this.$store.commit('changeLoginStatusMutations', false)
        this.$store.commit('changeCartNumMutations',0)
        if(this.$route.path != '/homeIndex'){
          await this.$router.push('/')
        }
        this.$message.success('已退出登录')
      }).catch((e) => {
        this.$message.info('已取消'+e)
      })
    },
  },
  computed:{
    ...mapState(['cartNum'])
  }
}
</script>

<style lang="less" scoped>
  .fixed-top{

  }
  .search-input {
    width: 378px;
    height: 14px;
    padding: 8px;
    border: 2px solid #EF001C;
    outline: 0;
    font-size: 12px;
    float: left;
    color: #999;
  }

  .search-click {
    width: 64px;
    height: 34px;
    cursor: pointer;
  }

  .my-shopping-cart {
    width: 158px;
    height: 35px;
    margin-top: -10px;
    cursor: pointer;

    .shopping-num {
      width: 16px;
      height: 16px;
      margin-top: -10px;
    }
  }

  .top-nav {
    z-index: 999999;
    display: none;
  }

  .top-nav-show {
    z-index: 999;
    display: block;
    background-color: rgba(255,255,255,0.9);
  }
</style>
