<template>
  <div class="w-1200 navigation df-ac">
    <div class="bg-main-color" style="width: 232px;height: 40px;">
      <span class="nav-title">全部商品分类</span>
      <div class="w-1200 quick-menu">
        <div class="menu-list">
          <div class="first-menu-list">
            <div class="first-menu-item" @mouseover="firstHover(index)" v-for="(item,index) in firstMenuList"
                 :key="item.id" @click="categoryItemClick(item.id)">
              <span class="name">{{item.cname}}</span>
              <div class="first-menu-second-menu">
                <div class="first-second-menu-list clearfix" v-if="item.children.length>0">
                    <span class="first-second-menu-item fl" v-for="itemChid in (item.children).slice(0,4)"
                          :key="itemChid.id" @click.stop="categoryItemClick(itemChid.id)">
                      {{itemChid.cname}}
                    </span>
                </div>
              </div>
            </div>
          </div>
          <div class="second-menu-list">
            <div class="right-menu-item" v-for="item in secondMenuList" :key="item.id">
              <div class="second-menu-title" @click="categoryItemClick(item.id)">{{item.cname}}</div>
              <div class="third-menu-list">
                <div class="third-menu-item" v-for="items in item.children" :key="items.id"
                     @click="categoryItemClick(items.id)">{{items.cname}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ml-3 flex-1 df-ac font-s-2 navigation-list" style="height: 40px;">
      <router-link v-for="item in topMenuList"
                   :to="item.url" :key="item.id">
        <div class="navigation-item text-dark-grey" :class="topMenuUrl == item.url?'text-main-color':''"
             @click="topMenuListClick(item.url)">{{item.name}}</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      firstMenuList: [],
      secondMenuList: [],
      topMenuList: [
        {
          name: '首页',
          url: '/'
        },
        {
          name: '品牌',
          url: '/brandList'
        },
        {
          name: '新品上市',
          url: '/newGoods'
        },
        {
          name: '热门排行',
          url: '/hotGoods'
        },
        {
          name: '精品推荐',
          url: '/recommendGoods'
        },
        // {
        //   name: '整点秒杀',
        //   url: '/seckillList'
        // },
        // {
        //   name: '推广分佣',
        //   url: '/distributionIndex'
        // },
        {
          name: '领券中心',
          url: '/receiveCoupon'
        },
        // {
        //   name: '助力中心',
        //   url: '/boostCenter'
        // },
      ],
      topMenuUrl: 0,
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.getAllCategoryList()
      this.getPageRoute()
    },
    // 获取分类
    async getAllCategoryList () {
      this.firstMenuList = this.$store.state.categoryMenuList
      if (this.firstMenuList.length <= 0) {
        // 调用vuex的 action 方法
        await this.$store.dispatch('changeCategoryMenuListActions')
        this.firstMenuList = this.$store.state.categoryMenuList
        this.secondMenuList = this.firstMenuList[0].children
      } else {
        this.secondMenuList = this.firstMenuList[0].children
      }
    },
    // 商品分类导航一级菜单移入事件
    firstHover (index) {
      this.secondMenuList = this.firstMenuList[index].children
    },
    // banner部分分类导航点击事件
    categoryItemClick (categoryId) {
      this.$router.push({ path: '/category', query: { categoryId } })
    },
    // 顶部菜单点击事件
    topMenuListClick (url) {
      this.topMenuUrl = url
    },
    // 获取路由，让对应的菜单高亮显示
    getPageRoute(){
      this.topMenuUrl = this.$route.path
    }
  }
}
</script>

<style lang="less" scoped>
  .navigation {
    height: 40px;

    span.nav-title {
      height: 40px;
      line-height: 40px;
      text-align: center;
      display: block;
    }

    .bg-main-color:hover .quick-menu {
      display: block;
    }
  }

  .navigation-list {
    color: #575757;

    .navigation-item:hover {
      color: #EF001C;
    }
  }

  .navigation-item {
    margin-right: 38px;
  }

  .quick-menu {
    position: relative;
    display: none;

    .menu-list {
      .first-menu-list {
        position: absolute;
        width: 232px;
        height: 510px;
        background-color: rgba(0, 0, 0, .6);
        z-index: 99;
        overflow-y: auto;

        .first-menu-item {
          margin-top: 18px;
          .name{
            cursor: pointer;
            font-size: 16px;
            line-height: 30px;
            padding-left: 20px;
            font-weight: bold;
          }
          .first-menu-second-menu{
            overflow: hidden;
            padding-left: 20px;
            height: 26px;
            .first-second-menu-list{

              .first-second-menu-item{
                font-size: 12px;
                color: #ffffff;
                margin-right: 5px;
                cursor: pointer;
              }
            }
          }
        }
      }

      .second-menu-list {
        display: none;
        position: absolute;
        width: 928px;
        height: 470px;
        left: 232px;
        background-color: #FFFFFF;
        z-index: 99;
        overflow-y: auto;
        box-shadow: 0 0 3px 2px rgba(0, 0, 0, .2);
        padding: 20px;

        .right-menu-item {
          display: flex;
          margin-bottom: 20px;

          .second-menu-title {
            font-size: 16px;
            color: #333333;
            margin-right: 20px;
            width: 80px;
            text-align: right;
            cursor: pointer;
          }

          .third-menu-list {
            display: flex;
            border-bottom: 1px #dddddd dashed;
            padding-bottom: 5px;
            flex-flow: wrap;
            width: 800px;

            .third-menu-item {
              cursor: pointer;
              margin-bottom: 5px;
              font-size: 14px;
              color: #666666;
              padding: 0 10px;
              border-left: 1px #dddddd solid;
              border-right: 1px #dddddd solid;
            }
          }
        }
      }
    }

    .menu-list:hover {
      .second-menu-list {
        display: block;
      }
    }
  }
</style>
