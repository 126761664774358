import Vue from 'vue'
import Vuex from 'vuex'
import { getCategory as getCategoryUrl, getCartListUrl, getBrandListUrl } from '@/api/index'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginStatus: false,
    selectCartGoodsInfo: [],
    cartOrderHttpObj: {},
    goodsDirectBuyHttpObj: {},
    paidOrderInfo: {},
    shopAppInfo: {},
    categoryMenuList: [],
    cartNum: 0,
    commonBrandList: []
  },
  mutations: {
    // 登录状态
    changeLoginStatusMutations (state, status) {
      state.loginStatus = status
    },
    // 修改已选择购物车的数量
    changeSelectCartGoodsInfoMutations (state, status) {
      state.selectCartGoodsInfo = status
    },
    // 购物车下单的参数
    changeCartOrderHttpObjMutations (state, status) {
      state.cartOrderHttpObj = status
    },
    // 商品详情页直接购买创建订单参数
    changeGoodsDirectBuyHttpObjMutations (state, status) {
      state.goodsDirectBuyHttpObj = status
    },
    // 未付款订单信息
    changePaidOrderInfoMutations (state, status) {
      state.paidOrderInfo = status
    },
    // 修改商城信息
    changeShopAppInfoMutations (state, status) {
      state.shopAppInfo = status
    },
    // 修改导航分类菜单
    changeCategoryMenuListMutations (state, status) {
      state.categoryMenuList = status
    },
    // 修改购物车数量
    changeCartNumMutations (state, num) {
      state.cartNum = num
    },
    // 修改通用品牌的列表
    changeCommonBrandListMutations (state, brandList) {
      state.commonBrandList = brandList
    },
  },
  actions: {
    // 获取分类导航接口
    async changeCategoryMenuListActions (context) {
      const { data: res } = await Vue.prototype.$http.get(getCategoryUrl)
      if (res.code !== 200) return this.$message.error(res.msg)
      context.commit('changeCategoryMenuListMutations', res.data)
    },
    // 获取购物车接口
    async changeCartNumActions (context) {
      const { data: res } = await Vue.prototype.$http.get(getCartListUrl)
      if (res.code !== 200) return Vue.prototype.$message.error(res.msg)
      context.commit('changeCartNumMutations', res.data.total)
    },
    // 获取商品品牌接口
    async changeCommonBrandListActions (context) {
      console.log(getBrandListUrl)
      /*let queryInfo={
        pageSize: 8,
        page: 1,
        field: '',
        desc: '',
      };
      const {data:res} = await Vue.prototype.$http.get(getBrandListUrl,{params:queryInfo})
      if (res.code !== 200) return Vue.prototype.$message.error(res.msg)*/
      context.commit('changeCommonBrandListMutations', [])
    }
  },
  modules: {}
})
