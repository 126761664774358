import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $json from './json.js'

Vue.prototype.$json = $json

// 图片懒加载
import VueLazyload from 'vue-lazyload'
import lasyLoadImg from '@/assets/img/lazy_load_img.png'

Vue.use(VueLazyload, {
  preLoad: 1.2,  // 提前加载的高度1.3表示1.3屏
  error: lasyLoadImg, // 错误图片
  loading: lasyLoadImg, // 正在加载的图片
  attempt: 3,  // 尝试次数
  listenEvents: ['scroll', 'wheel', 'mousewheel',
    'resize', 'animationend', 'transitionend',
    'touchmove'] // 监听哪些事件
})

// 全局loading
Vue.prototype.openLoading = function() {
  const loading = this.$loading({           // 声明一个loading对象
    lock: false,                             // 是否锁屏
    text: '正在加载...',                     // 加载动画的文字
    spinner: 'el-icon-loading',             // 引入的loading图标
    background: 'rgba(255, 255, 255, 0)',       // 背景颜色
    body: false,
    customClass: 'mask'                     // 遮罩层新增类名
  })
  // todo 因为感觉加上loading显得慢，所以设置时间为0
  setTimeout(function() {                  // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
    loading.close()                        // 关闭遮罩层
  }, 0)
  return loading
}

// 引入全局样式
import '@/assets/css/index.css'
import '@/assets/css/common.less'
// 引入图标
import '@/assets/fonts/iconfont.css'


// 引入element
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// elementUI样式重置
import '@/assets/css/elementUIReset.less'

import axios from 'axios'

// 配置请求的根路径
axios.defaults.baseURL = '/api'
//axios.defaults.baseURL = 'https://www.chunfengbuy.com/api'
// axios拦截器
axios.interceptors.request.use((config) => {
  // console.log(config);
  // 把token添加到请求头里面
  if(window.localStorage.getItem('token')){
    config.headers.token = window.localStorage.getItem('token')
  }
  // 最后必须return config
  return config
})
Vue.prototype.$http = axios
Vue.use(ElementUI)

Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)}).$mount('#app')

//判断配置文件是否开启日志调试 是否输出日志 True 输出 False 不输出
var logDebug = false;
console.log = (function (oriLogFunc) {
  return function () {
    if (logDebug) {
      oriLogFunc.apply(this, arguments);
    }
  }
})(console.log);
