<template>
  <div>
    <!-- 公共头 -->
    <common-header></common-header>
    <!-- 公共分类导航 -->
    <common-navigation></common-navigation>
    <div class="user-info-container">
      <el-container class="w-1200">
        <el-aside width="260px">
          <div class="user-info">
            <router-link to="/userCenter/userInfo" class="d-f">
              <div class="avatar">
                <el-image
                  :src="userInfo.avatar"
                  fit="fill"></el-image>
              </div>
              <div class="name ml-1">
                <h2>{{userInfo.userName}}</h2>
                <div class="user-rank user-rank-1">铜牌</div>
              </div>
            </router-link>
          </div>
          <div class="user-center-menu">
            <el-menu
              :default-active="activePath"
              :router="true"
              :default-openeds="['1','2','3','4']"
              @open="handleOpen"
              @close="handleClose">
              <el-submenu :index="item.id" v-for="item in menuList" :key="item.id">
                <template slot="title">
                  <i class="el-icon-menu"></i>
                  <span>{{item.title}}</span>
                </template>
                <el-menu-item-group>
                  <el-menu-item v-for="menuChild in item.children"
                                :key="menuChild.id"
                                :index="menuChild.url" @click="saveMenuLink(menuChild.url)">
                    {{menuChild.title}}
                  </el-menu-item>
                </el-menu-item-group>
              </el-submenu>
            </el-menu>
          </div>
        </el-aside>
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </div>
    <!-- 公共底 -->
    <common-footer></common-footer>
    <div style="width: 100%;height: 100%;">
      <el-backtop :bottom="60"></el-backtop>
    </div>
  </div>
</template>

<script>
import commonHeader from '@/components/common/common-header.vue'
import commonFooter from '@/components/common/common-footer.vue'
import commonNavigation from '@/components/common/common-navigation.vue'
import { getDistributeUserCenterUrl,distributionUserInfoUrl, userInfo as getUserInfoUrl, } from '@/api/index'

export default {
  name: 'userCenter',
  components: {
    commonHeader,
    commonFooter,
    commonNavigation
  },
  data () {
    return {
      // 当前页面链接
      activePath: '',
      menuList: [
        {
          id: '1',
          title: '订单中心',
          children: [
            {
              id: 11,
              title: '我的订单',
              url: '/userCenter/order'
            },
            {
              id: 12,
              title: '收货地址',
              url: '/userCenter/address'
            },
          ]
        },
        {
          id: '2',
          title: '会员中心',
          children: [
            {
              id: 21,
              title: '用户信息',
              url: '/userCenter/userInfo'
            },
            {
              id: 22,
              title: '账户安全',
              url: '/order'
            },
            {
              id: 23,
              title: '账号绑定',
              url: '/order'
            },
          ]
        },
        {
          id: '3',
          title: '账户中心',
          children: [
            {
              id: 31,
              title: '我的红包',
              url: '/userCenter/myRedEnvelopeList'
            },
            {
              id: 32,
              title: '我的优惠券',
              url: '/coupon/myCoupon'
            },
            {
              id: 33,
              title: '我的助力',
              url: '/userCenter/MyBoost'
            },
          ]
        }
      ],
      userInfo: {
        userName: '',
        avatar: ''
      }

    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData () {
      this.getUserInfo()
      this.setLeftMenuActive()
      setTimeout(() => {
        this.getDistributeUserCenterInfo()
      })
    },
    // 获取用户登录信息
    async getUserInfo () {
      let token = window.localStorage.getItem('token')
      if (token == '' || token == null) {
        this.$router.push('/login')
        return false
      }
      let userInfo = window.sessionStorage.getItem('userInfo')
      if (userInfo == '' || userInfo == null) {
        const { data: userInfoRes } = await this.$http.get(getUserInfoUrl)
        if (userInfoRes.code !== 200) return this.$message.error(userInfoRes.msg)
        window.sessionStorage.setItem('userInfo', JSON.stringify(userInfoRes.data))
        this.userInfo = userInfoRes.data
        this.userInfo.userName = userInfoRes.data.name
        this.userInfo.avatar = userInfoRes.data.image
      } else {
        this.userInfo = JSON.parse(userInfo)
        this.$store.commit('changeLoginStatusMutations', true)
        this.userInfo.userName = JSON.parse(userInfo).name
        this.userInfo.avatar = JSON.parse(userInfo).image
      }
    },
    // 获取个人中心推广信息
    async getDistributeUserCenterInfo () {
      let distributeUserCenterInfo = window.sessionStorage.getItem('distributeUserCenterInfo')
      let distributeMenu = {
        id: '4',
        title: '推广中心',
        children: [
          {
            id: 41,
            title: '推广信息',
            url: '/distribution/myDistributionInfo'
          },
          {
            id: 42,
            title: '推广订单',
            url: '/distribution/DistributionOrders'
          },
          {
            id: 46,
            title: '提现记录',
            url: '/distribution/withdrawalList'
          },
          {
            id: 43,
            title: '收益明细',
            url: '/distribution/profitDetails'
          },
          {
            id: 44,
            title: '挑选商品',
            url: '/distribution/choiceDistributionGoods'
          },
          {
            id: 45,
            title: '我的下级',
            url: '/distribution/mySubordinate'
          }
        ]
      }
      let notDistributeMenu = {
        id: '4',
        title: '推广中心',
        children: [
          {
            id: 41,
            title: '申请成为推广员',
            url: '/distribution/applyDistribution'
          },
          {
            id: 42,
            title: '查看申请进度',
            url: '/distribution/distributionStatus'
          },
        ]
      }
      if (distributeUserCenterInfo == '' || distributeUserCenterInfo == null || JSON.stringify(distributeUserCenterInfo) == '{}') {
        const { data: res } = await this.$http.get(getDistributeUserCenterUrl)
        if (res.code !== 200) return this.$message.error(res.msg)
        window.sessionStorage.setItem('distributeUserCenterInfo', JSON.stringify(res.data))
        console.log(!(res.data.isDistribute))
        if (res.data.isDistribute) {
          // 如果是分销员，给菜单里面加入分销员的菜单
          this.menuList.push(distributeMenu)
          this.getDistributionUserInfo()
        } else {
          // 如果不是分销员，添加成为分销员菜单
          this.menuList.push(notDistributeMenu)
        }
      } else {
        if (JSON.parse(distributeUserCenterInfo).isDistribute) {
          // 如果是分销员，给菜单里面加入分销员的菜单
          this.menuList.push(distributeMenu)
          this.getDistributionUserInfo()
        } else {
          // 如果不是分销员，添加成为分销员菜单
          this.menuList.push(notDistributeMenu)
        }
      }
    },
    // 获取推广中心首页内容
    async getDistributionUserInfo () {
      let distributionUserInfo = window.sessionStorage.getItem('distributionUserInfo')
      if (distributionUserInfo == '' || distributionUserInfo == null || JSON.stringify(distributionUserInfo) == '{}') {
        const { data: res } = await this.$http.get(distributionUserInfoUrl)
        if (res.code !== 200) return this.$message.error(res.msg)
        window.sessionStorage.setItem('distributionUserInfo', JSON.stringify(res.data))
      }
    },
    handleOpen (key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      console.log(key, keyPath)
    },
    // 左侧二级菜单显示
    setLeftMenuActive () {
      this.activePath = this.$route.path
    },
    // 二级菜单点击事件
    saveMenuLink (activePath) {
      this.activePath = activePath
    },
  }
}
</script>

<style lang="less" scoped>
  .user-info-container {
    background-color: #f5f5f5;
    padding: 20px 0;
  }

  .user-info {
    background: #fff;
    padding: 20px 30px;
    margin-bottom: 10px;

    .avatar {
      .el-image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
      }
    }

    .name {
      h2 {
        font-size: 16px;
        font-weight: bolder;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #666666;
        height: 18px;
      }

      .user-rank {
        background: url(../assets/img/user_rank.png) no-repeat;
        width: 90px;
        height: 17px;
        display: inline-block;
        vertical-align: middle;
        color: #fff;
        text-indent: 40px;
        font-size: 12px;
      }

      .user-rank-1 {
        background-position: -25px 0;
      }
    }

  }
</style>
