<template>
  <div class="">
    <!-- 公共头 -->
    <common-header></common-header>
    <!-- 公共分类导航 -->
    <common-navigation></common-navigation>
    <router-view></router-view>
    <!-- 公共底 -->
    <common-footer></common-footer>

    <div style="width: 100%;height: 100%;">
      <el-backtop :bottom="60"></el-backtop>
    </div>
  </div>
</template>

<script>
import commonHeader from '@/components/common/common-header.vue';
import commonFooter from '@/components/common/common-footer.vue';
import commonNavigation from '@/components/common/common-navigation.vue';
import lasyLoadImg from '@/assets/img/lazy_load_img.png'

export default {
  components: {
    commonHeader,
    commonFooter,
    commonNavigation
  },
  name: 'Index',
  data(){
    return{
      imgObj: {
        src: lasyLoadImg,
        error: lasyLoadImg,
        loading: lasyLoadImg
      },
      imgUrl: lasyLoadImg // String
    }
  }
};
</script>


<style lang="less" scoped>

</style>
