<template>
  <div class="w-100  clear-both">
    <div class="w-100" style="height: 76px;background-color: #EAEAEA;">
      <div class="w-1200 df-ac-js" style="height: 76px;">
        <div class="df-ac">
          <div class="df-ac mr-1 service-list" v-for="(item,index) in serviceList" :key="index">
            <div class="iconfont df-ac-jc text-white mr-1 font-w" :class="item.icon"></div>
            <div class="text-grey font-s-4">{{ item.service }}</div>
          </div>
        </div>
        <div class="df-ac">
          <div class="df-ac">
            <div class="iconfont  icon-dianhua text-grey mr-1"></div>
            <div class="font-s-4 font-w">13888888888</div>
          </div>
          <div class="df-ac ml-4">
            <div class="iconfont icon-kefu text-grey mr-1"></div>
            <div class="font-s-4 font-w">客服咨询</div>
          </div>
        </div>
      </div>
    </div>
    <div class="w-100 footer-box">
      <div class="footer-help w-1200">
        <div class="help-list" v-for="item in footerHelp" :key="item.id">
          <div class="help-title">{{item.title}}</div>
          <div class="help-menu">
            <router-link class="router-link" to="/" v-for="itemMeun in item.menuList" :key="itemMeun.id">{{itemMeun.text}}</router-link>
          </div>
        </div>
      </div>
      <div class="footer-nav w-100">
        <div class="nav-list w-1200">
          <router-link class="router-link" :to="item.url || '/'" v-for="item in footerNavList"
                       :key="item.id">{{item.text}}</router-link>
        </div>
      </div>
    </div>
    <div class="w-100 footer-copy-right">
      © 2016 - 2020 RageFrame All Rights Reserved
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data () {
    return {
      serviceList: [
        { icon: 'icon-tianmaoqitiantuihuo', service: '七天包换' },
        { icon: 'icon-zhengpin', service: '正品保障' },
        { icon: 'icon-haoping', service: '好评如潮' },
        { icon: 'icon-shandian', service: '闪电发货' },
        { icon: 'icon-quanweizhuanjia', service: '权威荣誉' }
      ],
      footerHelp: [
        {
          id: 1,
          title: '购物指南',
          menuList: [
            {
              id:11,
              text:'购物流程',
              url:''
            },{
              id:12,
              text:'会员介绍',
              url:''
            },{
              id:13,
              text:'生活旅行/团购',
              url:''
            },{
              id:14,
              text:'常见问题',
              url:''
            },{
              id:15,
              text:'联系客服',
              url:''
            },
          ]
        },
        {
          id: 2,
          title: '配送方式',
          menuList: [
            {
              id:21,
              text:'上门自提',
              url:''
            },{
              id:22,
              text:'211限时达',
              url:''
            },{
              id:23,
              text:'配送服务查询',
              url:''
            },{
              id:24,
              text:'配送费收取标准',
              url:''
            },{
              id:25,
              text:'海外配送',
              url:''
            }
          ]
        },
        {
          id: 3,
          title: '支付方式',
          menuList: [
            {
              id:31,
              text:'货到付款',
              url:''
            },{
              id:32,
              text:'在线支付',
              url:''
            },{
              id:33,
              text:'分期付款',
              url:''
            },{
              id:34,
              text:'公司转账',
              url:''
            }
          ]
        },
        {
          id: 4,
          title: '售后服务',
          menuList: [
            {
              id:41,
              text:'售后政策',
              url:''
            },{
              id:42,
              text:'价格保护',
              url:''
            },{
              id:43,
              text:'退款说明',
              url:''
            },{
              id:44,
              text:'返修/退换货',
              url:''
            },{
              id:45,
              text:'取消订单',
              url:''
            }
          ]
        },
        {
          id: 5,
          title: '特色服务',
          menuList: [
            {
              id:51,
              text:'夺宝岛',
              url:''
            },{
              id:52,
              text:'DIY装机',
              url:''
            },{
              id:53,
              text:'延保服务',
              url:''
            },{
              id:54,
              text:'E卡',
              url:''
            }
          ]
        },
      ],
      footerNavList:[
        {
          id:1,
          text:'关于我们',
          url:''
        },{
          id:2,
          text:'联系我们',
          url:''
        },{
          id:3,
          text:'联系客服',
          url:''
        },{
          id:4,
          text:'合作招商',
          url:''
        },{
          id:5,
          text:'商家帮助',
          url:''
        },{
          id:6,
          text:'营销中心',
          url:''
        },{
          id:7,
          text:'手机商城',
          url:''
        },{
          id:8,
          text:'友情链接',
          url:''
        },{
          id:9,
          text:'销售联盟',
          url:''
        },{
          id:10,
          text:'商城社区',
          url:''
        },{
          id:11,
          text:'隐私政策',
          url:''
        },{
          id:12,
          text:'商城公益',
          url:''
        },{
          id:13,
          text:'English Site',
          url:''
        },
      ]
    }
  }
}
</script>

<style lang="less" scoped>
  .service-list {
    width: 170px;
    height: 32px;

    .iconfont {
      width: 36px;
      height: 36px;
      background-color: #F5695D;
      border-radius: 4px;
      font-size: 26px;
    }
  }
  .footer-box {
    background-color: #1c1c1c;
    padding: 30px 0 20px;
    .router-link{
      text-decoration: none;
      color: #999999;
      font-size: 12px;
      height: 30px;
      line-height: 30px;
    }
    .footer-help{
      color: #dddddd;
      display: flex;
      justify-content: space-between;
      width: 800px;
      margin: 0 auto;
      .help-list{
        .help-title{
          font-size: 14px;
          height: 30px;
          line-height: 30px;
        }
        .router-link{
          display: block;
        }
      }
    }
    .footer-nav{
      padding-top: 20px;
      .nav-list{
        text-align: center;
        .router-link{
          padding: 0 10px;
          border-left: 1px solid #dddddd;
          &:first-child{
            border-left: none;
            padding-left: 0;
          }
        }

      }
    }
  }
  .footer-copy-right{
    background-color: #111111;
    padding: 20px 0;
    color: #eeeeee;
    font-size: 14px;
    text-align: center;
  }
</style>
