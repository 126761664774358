<template>
  <div>
    <!-- 轮播导航开始 -->
    <div class="banner-info" style="background-color: #F1F1F1;">
      <div class="w-1200 d-f">
        <div class="quick-menu">
          <div class="menu-list">
            <div class="first-menu-list">
              <div class="first-menu-item text-dark-grey" @mouseover="firstHover(index)"
                   v-for="(item,index) in firstMenuList"
                   :key="item.id" @click="categoryItemClick(item.id)">
                <span class="name">{{item.cname}}</span>
                <div class="first-menu-second-menu">
                  <div class="first-second-menu-list clearfix" v-if="item.children.length>0">
                    <span class="first-second-menu-item fl" v-for="itemChid in (item.children).slice(0,4)"
                          :key="itemChid.id" @click.stop="categoryItemClick(itemChid.id)">
                      {{itemChid.cname}}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="second-menu-list">
              <div class="right-menu-item" v-for="item in secondMenuList" :key="item.id">
                <div class="second-menu-title" @click="categoryItemClick(item.id)">{{item.cname}}</div>
                <div class="third-menu-list">
                  <div class="third-menu-item" v-for="items in item.children" :key="items.id"
                       @click="categoryItemClick(items.id)">{{items.cname}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <el-carousel class="index-swiper mt-1" height="500px">
          <el-carousel-item v-for="item in bannerList" :key="item.id">
            <img v-lazy="item.pc_image" style="height:500px"/>
          </el-carousel-item>
        </el-carousel>
        <div class="ml-1 mt-1" style="width: 218px;height: 500px;background-color: #ffffff;">
          <div class="w-100" style="height: 249px;border-bottom: 1px #f1f1f1 dashed;">
            <div class="py-1 ml-1 font-s-2" style="border-bottom: 1px #f1f1f1 dashed;">会员</div>
            <div class="w-100 flex-1 df-a-j-fc user-states" v-if="loginStatus">
              <div class="user-img border mt-2 df-ac-jc">
                <img v-lazy="userInfo.image" class="img"/>
              </div>
              <div class="text-dark-grey df-ac-jc mt-2 font-s-3">
                {{userInfo.name}}
              </div>
              <div class="text-dark-grey df-ac-jc mt font-s-2">
                欢迎来到{{shopAppInfo.shop_name}}
              </div>
            </div>
            <div class="w-100 flex-1 df-a-j-fc user-states" v-else>
              <div class="user-img border mt-2 df-ac-jc">
                <img :src="onproto" class="img"/>
              </div>
              <router-link to="/login">
                <div class="text-main-color df-ac-jc mt-2 font-s-2"
                     style="width: 90px;height: 25px;border: 1px red solid;">
                  前往登陆
                </div>
              </router-link>
            </div>
          </div>
          <div class="w-100">
            <div class="py-1 ml-1 font-s-2" style="border-bottom: 1px #f1f1f1 dashed;">商城服务</div>
            <div class="w-100 d-f flex-wrap text-main-color">
              <div class="df-a-j-fc entrance-item">
                <div class="iconfont icon-dianhua"></div>
                <div class="font-s-1 text-dark-grey">我的收藏</div>
              </div>
              <div class="df-a-j-fc entrance-item">
                <div class="iconfont icon-dianhua"></div>
                <div class="font-s-1 text-dark-grey">我的收藏</div>
              </div>
              <div class="df-a-j-fc entrance-item">
                <div class="iconfont icon-dianhua"></div>
                <div class="font-s-1 text-dark-grey">我的收藏</div>
              </div>
              <div class="df-a-j-fc entrance-item">
                <div class="iconfont icon-dianhua"></div>
                <div class="font-s-1 text-dark-grey">我的收藏</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- 轮播导航结束 -->
    <div style="background-color: #F1F1F1;">
      <!-- 秒杀活动开始 -->
     <!-- <div class="seckill-box w-1200 d-f justify-start pt-4" v-if="indexSeckillInfo.list.length>0">
        <div class="left-banner">
          <div class="active-name">打折秒杀</div>
          <div class="active-eng">FLASH DEALS</div>
          <div class="time-tip">本场距离结束还剩</div>
          <div class="time-tip">{{indexSeckillTime.hou}}:{{indexSeckillTime.min}}:{{indexSeckillTime.sec}}</div>
        </div>
        <div class="right-goods-list d-f">
          <div class="seckill-goods-item" v-for="item in indexSeckillInfo.list" :key="item.id"
               @click="toSeckillProduct(item)">
            <img v-lazy="item.slideList.img"/>
            <div class="seckill-goods-name font-s-2 text-grey in1line">{{item.title}}</div>
            <div class="seckill-goods-price">
              <span class="seckill-price font-w">￥{{item.seckillPrice}}</span>
              <span class="price">￥{{item.price}}</span>
            </div>
          </div>
        </div>
      </div> -->
      <!-- 秒杀活动结束 -->

      <!-- 分类导航开始 -->
      <div class="d-f justify-between flex-wrap w-1200 pt-2">
        <div class="bg-white mb-2" style="width: 594px;" v-for="item in firstMenuList.slice(0,4)" :key="item.id">
          <div class="df-ac-js" style="background-color:#F8F8F8;">
            <div class="py-1 df-ac">
              <div class="mr-1" style="width: 3px;height: 18px;background-color: chocolate; "></div>
              <div class="font-w font-s-4" style="color: chocolate">{{item.cname}}</div>
            </div>
            <div class="df-ac py-1 mr-2 font-s-2 text-dark-grey" v-if="item.children.length>0">
              <div class="mr-2" v-for="itemChid in (item.children).slice(0,4)" :key="itemChid.id">{{itemChid.cname}}
              </div>
            </div>
          </div>
          <div class="w-100 d-f">
            <div class="left-img">
              <img v-lazy="item.cover" style="width: 100%;height: 100%;" @click="categoryItemClick(item.id)"/>
            </div>
            <div class=" flex-wrap d-f" style="width: 396px;" v-if="item.children.length>0">
              <div class="category-sun-border df-a-j-fc" style="" v-for="itemChid in (item.children).slice(0,4)"
                   :key="itemChid.id" @click="categoryItemClick(itemChid.id)">
                <div class="font-s-2 text-dark-grey mt">{{itemChid.cname}}</div>
                <div class="font-s-1" style="color: chocolate">{{itemChid.slogan}}</div>
                <div class="df-ac-jc" style="width: 118px;height: 118px;">
                  <img v-lazy="itemChid.cover"
                       class="category-sun-img"/>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 d-f" v-if="item.children.length>0">
            <div class="category-sun-tree df-ac" v-for="thirdChild in (item.children[0].children).slice(0,3)"
                 :key="thirdChild.id" @click="categoryItemClick(thirdChild.id)">
              <div class="ml-2">
                <div class="font-s-2 text-dark-grey">{{thirdChild.cname}}</div>
                <div class="font-s-1" style="color: chocolate">{{thirdChild.slogan}}</div>
              </div>
              <div class="category-sun-tree-img df-ac-jc mr-2">
                <img v-lazy="thirdChild.cover"
                     class="category-sun-img"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 分类导航结束 -->

      <!-- 新品商品开始 -->
      <!--<div class="w-1200 mt-2">
        <div class="w-100 df-ac-js" style="height: 43px;background-color: #16AEBC;">
          <div class="ml-3 font-s-6 text-white">新品商品</div>
        </div>
        <div class="w-100 d-f">
          <div class="" style="width: 200px;height: 520px;">
            <img src="@/assets/img/391.jpeg"/>
          </div>
          <index-common-goods-list :goodsList="newGoodsList"></index-common-goods-list>
        </div>
      </div>-->
      <!-- 新品商品结束 -->

      <!-- 猜您喜欢 -->
      <div class="w-1200">
        <div class="w-100 df-ac-jc" style="height: 90px;">
          <div class="flex-1 guessyou-line "></div>
          <div class="font-s-6 text-grey font-w px-3">猜您喜欢</div>
          <div class="flex-1 guessyou-line"></div>
        </div>
        <index-like-goods :goodsList="likeGoodsList"></index-like-goods>
      </div>
    </div>

    <!-- 右侧固定导航开始 -->
    <div class="right-fix-nav">
      <div class="nav-list">
        <div class="nav-item" v-for="item in rightFixNavList" :key="item.id" @click="rightNavClick(item)">
          <i :class="item.icon" v-if="item.icon"></i>
          <div class="text">{{item.text}}</div>
        </div>
      </div>
    </div>
    <!-- 右侧固定导航结束 -->

    <!-- 红包弹窗开始 -->
    <div class="red-envelope-box" v-if="redEnvelopeInfo.boxStatus">
      <div class="red-envelope-content is-receive" v-if="redEnvelopeInfo.receiveStatus" @click="closeRedEnvelopeBox">
        <div class="close el-icon-close"></div>
        <div class="money">￥<span>{{redEnvelopeInfo.value}}</span></div>
        <div class="name">{{redEnvelopeInfo.name}}</div>
        <div class="time">使用时间：{{redEnvelopeInfo.hour}}小时</div>
        <div class="need">满{{redEnvelopeInfo.minAmount}}可用</div>
      </div>
      <div class="red-envelope-content no-receive" v-else @click="ReceiveRedEnvelope">
        <div class="close el-icon-close" @click="closeRedEnvelopeBox"></div>
        <div class="red-envelope-name">{{redEnvelopeInfo.name}}</div>
      </div>
    </div>
    <!-- 红包弹窗结束 -->
  </div>
</template>

<script>
import indexLikeGoods from '@/components/productList/IndexLikeGoods.vue'
import {
  getBanner, userInfo as getUserInfoUrl, getLikeGoodsUrl, getConfigUrl,
  getSeckillIndexUrl,getBrandListUrl,checkRedEnvelopeStatus,receiveRedEnvelopeUrl
} from '@/api/index'

export default {
  name: 'HomeIndex',
  components: {
    indexLikeGoods
  },
  data () {
    return {
      goodsList: this.$json.goodsList,
      categoryIndex: '0',
      bannerList: [],
      firstMenuList: [],
      secondMenuList: [],
      newGoodsQueryInfo: {
        page: 1,
        size: 10,
        sort: '',
        order: ''
      },
      getLikeGoodsId: [],
      likeGoodsList: [],
      brandList:[],
      brandListQueryInfo:{
        pageSize: 10,
        page: 1,
        field: '',
        desc: '',
      },
      onproto: require('@/assets/img/header-img.jpeg'),
      loginStatus: false,
      userInfo: {},
      shopAppInfo: {},
      rightFixNavList: [
        {
          id: 1,
          text: '限时秒杀',
          icon: '',
          url: '/seckillList'
        }, {
          id: 2,
          text: '特色优选',
          icon: ''
        }, {
          id: 3,
          text: '频道广场',
          icon: ''
        }, {
          id: 4,
          text: '为你推荐',
          icon: ''
        }, {
          id: 5,
          text: '客服',
          icon: 'el-icon-service'
        }, {
          id: 6,
          text: '反馈',
          icon: 'el-icon-edit'
        }
      ],
      indexSeckillInfo: {
        list: []
      },
      indexSeckillTime: {
        hou: 0,
        min: 0,
        sec: 0
      },
      redEnvelopeInfo:{
        // 整个弹窗的状态
        boxStatus:false,
        // 领取状态，true为显示已领取红包的弹窗false为未领取
        receiveStatus:false,
        // 红包名称
        name:'',
        // 红包金额
        value:'',
        // 最小使用金额
        minAmount:'',
        // 可使用时间
        hour:''
      }
    }
  },
  created () {
    this.initData()
  },
  methods: {
    async initData () {
      this.getRedEnvelopeStatus()
      this.getBannerList()
      this.getAllCategoryList()
      for (let i = 0; i < 4; i++) {
        // 因为项目经理要猜你喜欢显示40个，但是这个接口是没有分页的，所有只能循环4次
        this.getLikeGoodsList()
      }
      this.getUserInfo()
      this.getShopAppInfo()
      this.getSeckillIndex()
      this.getBrandList()
    },
    // 获取红包领取状态
    async getRedEnvelopeStatus(){
      if(!window.sessionStorage.getItem('redEnvelopeStatus')){
        const{data:res} = await this.$http.get(checkRedEnvelopeStatus)
        console.log(res)
        if(res.code === 45001){
          this.redEnvelopeInfo.boxStatus = false
        }else if(res.code === 200){
          this.redEnvelopeInfo.boxStatus = true
          this.redEnvelopeInfo.name = res.data.name
        }else {
          this.$message.error(res.msg)
        }
      }
    },
    // 领取红包
    async ReceiveRedEnvelope(){
      const {data:res} = await this.$http.get(receiveRedEnvelopeUrl)
      if(res.code !== 200) return this.$message.error(res.msg)
      console.log(res)
      this.redEnvelopeInfo.receiveStatus = true
      this.redEnvelopeInfo.value = res.data.value
      this.redEnvelopeInfo.minAmount = res.data.minAmount
      this.redEnvelopeInfo.hour = res.data.hour
    },
    // 关闭红包弹窗
    closeRedEnvelopeBox(){
      this.redEnvelopeInfo.boxStatus = false
      // 存到sessionStorage里面，防止不领取，刷新页面也重新显示
      window.sessionStorage.setItem('redEnvelopeStatus', false)
    },
    // 获取用户信息
    async getUserInfo () {
      // 先从vuex中判断是否登录
      this.loginStatus = this.$store.state.loginStatus
      let token = window.localStorage.getItem('token')
      let userInfo = window.sessionStorage.getItem('userInfo')
      if (!this.loginStatus) {
        // 再判断缓存中有没有token和用户信息
        if (token == '' || token == null) {
          // token为空清除userInfo的缓存
          window.sessionStorage.removeItem('userInfo')
          this.$store.commit('changeLoginStatusMutations', false)
          return false
        } else {
          this.loginStatus = true
          this.$store.commit('changeLoginStatusMutations', true)
        }
        if (userInfo == '' || userInfo == null) {
          const { data: userInfoRes } = await this.$http.get(getUserInfoUrl)
          if (userInfoRes.code !== 200) return this.$message.error(userInfoRes.msg)
          window.sessionStorage.setItem('userInfo', JSON.stringify(userInfoRes.data))
          this.userInfo = userInfoRes.data
        } else {
          this.userInfo = JSON.parse(userInfo)
          this.$store.commit('changeLoginStatusMutations', true)
        }
      } else {
        if (userInfo == '' || userInfo == null) {
          const { data: userInfoRes } = await this.$http.get(getUserInfoUrl)
          if (userInfoRes.code !== 200) return this.$message.error(userInfoRes.msg)
          window.sessionStorage.setItem('userInfo', JSON.stringify(userInfoRes.data))
          this.userInfo = userInfoRes.data
        } else {
          this.userInfo = JSON.parse(userInfo)
          this.$store.commit('changeLoginStatusMutations', true)
        }
      }
    },
    // 获取商城基本信息
    async getShopAppInfo () {
      this.shopAppInfo = this.$store.state.shopAppInfo
      if (JSON.stringify(this.shopAppInfo) == '{}') {
        // 因为会在后台自动配置，随时会修改，不能做缓存所以直接放到vuex中
        const { data: res } = await this.$http.get(getConfigUrl)
        if (res.code !== 200) return this.$message.error(res.msg)
        this.shopAppInfo = res.data
        this.$store.commit('changeShopAppInfoMutations', this.shopAppInfo)
      }
    },
    // 获取banner列表
    async getBannerList () {
      const { data: res } = await this.$http.get(getBanner)
      if (res.code !== 200) return this.$message.error(res.msg)
      res.data.forEach((item) => {
        if (item.pc_image != '') {
          this.bannerList.push(item)
        }
      })
    },
    // 获取分类
    async getAllCategoryList () {
      this.firstMenuList = this.$store.state.categoryMenuList
      if (this.firstMenuList.length <= 0) {
        // 调用vuex的 action 方法
        await this.$store.dispatch('changeCategoryMenuListActions')
        this.firstMenuList = this.$store.state.categoryMenuList
        this.secondMenuList = this.firstMenuList[0].children
      } else {
        this.secondMenuList = this.firstMenuList[0].children
      }
    },
    // 获取首页秒杀商品列表
    async getSeckillIndex () {
      let httpObj = { page: 1, pageSize: 5 }
      const { data: res } = await this.$http.get(getSeckillIndexUrl, { params: httpObj })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.indexSeckillInfo = res.data
      if (this.indexSeckillInfo.list.length <= 0) {
        return false
      }
      // 获取当前时间
      let date1 = new Date()
      // 去掉时间戳的毫秒
      let nowTime = Number(String(date1.getTime()).substring(0, 10))
      // 计算当前时间和秒杀活动的剩余时间
      let surplusTime = this.indexSeckillInfo.endAt - nowTime
      if (surplusTime > 0) {
        this.indexSeckillTime.hou = parseInt((surplusTime % (60 * 60 * 24)) / 3600) < 10 ? ('0' + parseInt((surplusTime % (60 * 60 * 24)) / 3600)) : parseInt((surplusTime % (60 * 60 * 24)) / 3600)
        this.indexSeckillTime.min = parseInt(((surplusTime % (60 * 60 * 24)) % 3600) / 60) < 10 ? ('0' + parseInt(((surplusTime % (60 * 60 * 24)) % 3600) / 60)) : parseInt(((surplusTime % (60 * 60 * 24)) % 3600) / 60)
        this.indexSeckillTime.sec = parseInt(((surplusTime % (60 * 60 * 24)) % 3600) % 60) < 10 ? ('0' + parseInt(((surplusTime % (60 * 60 * 24)) % 3600) % 60)) : parseInt(((surplusTime % (60 * 60 * 24)) % 3600) % 60)
      }
      let timer = setInterval(() => {
        --this.indexSeckillTime.sec
        if (this.indexSeckillTime.sec < 0) {
          this.indexSeckillTime.sec = 59
          --this.indexSeckillTime.min
          if (this.indexSeckillTime.min < 0) {
            this.indexSeckillTime.min = 59
            --this.indexSeckillTime.hou
            if (this.indexSeckillTime.hou < 0) {
              // 如果秒杀结束，清除倒计时，重新获取秒杀接口
              clearInterval(timer)
              this.getSeckillIndex()
            } else if (this.indexSeckillTime.hou < 10) {
              // 小于10在前面追加0
              this.indexSeckillTime.hou = '0' + this.indexSeckillTime.hou
            }
          } else if (this.indexSeckillTime.min < 10) {
            // 小于10在前面追加0
            this.indexSeckillTime.min = '0' + this.indexSeckillTime.min
          }
        } else if (this.indexSeckillTime.sec < 10) {
          // 小于10在前面追加0
          this.indexSeckillTime.sec = '0' + this.indexSeckillTime.sec
        }
      }, 1000)
    },
    // 获取品牌列表
    async getBrandList(){
      const { data: res } = await this.$http.get(getBrandListUrl,{params:this.brandListQueryInfo})
      if (res.code !== 200) return this.$message.error(res.msg)
      this.brandList = res.data.list
    },

    // 商品分类导航一级菜单移入事件
    firstHover (index) {
      this.secondMenuList = this.firstMenuList[index].children
    },
    // banner部分分类导航点击事件
    categoryItemClick (categoryId) {
      this.$router.push({ path: '/category', query: { categoryId } })
    },
    clickCategory (index) {
      this.categoryIndex = index
    },
    // 跳转到商品详情页
    toProductDetail (productInfo) {
      this.$router.push({ path: '/product', query: { productId: productInfo.id } })
    },
    // 猜你喜欢
    async getLikeGoodsList () {
      const { data: res } = await this.$http.post(getLikeGoodsUrl + `?ids=[${this.getLikeGoodsId}]`)
      if (res.code !== 200) return this.$message.error(res.msg)
      // 把猜你喜欢的id放到数组里面
      res.data.forEach(item => {
        this.getLikeGoodsId.push(item.id)
      })
      let arr = [...this.likeGoodsList, ...res.data]
      const resArr = new Map()
      this.likeGoodsList = arr.filter(arr => !resArr.has(arr.id) && resArr.set(arr.id, 1))
    },
    // 右侧固定导航点击事件
    rightNavClick (item) {
      if (item.url) {
        this.$router.push('/seckillList')
      }
    },
    // 跳转到秒杀商品详情页
    toSeckillProduct (productInfo) {
      this.$router.push({
        path: '/product', query: {
          productId: productInfo.id,
          pageStatus: 'seckill',
          activeId: productInfo.activityId,
          productStatus: '1'
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
  .red-envelope-box{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.6);
    z-index: 999999;
    .red-envelope-content{
      position: relative;
      width: 500px;
      height: 750px;
      left: 50%;
      top: 50%;
      transform:translate(-50%,-40%);
      text-align: center;
      cursor: pointer;
      .close{
        position: absolute;
        right: -40px;
        top: -40px;
        font-size: 24px;
        color: #ffffff;
        padding: 4px;
        border: 2px solid #ffffff;
        border-radius: 50%;
      }
    }
    .no-receive{
      background: url("https://www.chunfengbuy.com/image/open-before.png") no-repeat center;
      background-size: cover;
      .red-envelope-name{
        font-size: 40px;
        line-height: 500px;
        color: #ffffff;
      }
    }
    .is-receive{
      background: url("https://www.chunfengbuy.com/image/open-after.png") no-repeat center;
      background-size: cover;
      .money{
        font-size: 48px;
        color: red;
        font-weight: bold;
        padding-top: 84px;
        span{
          font-size: 72px;
        }
      }
      .name{
        font-size: 40px;
        color: #ffffff;
        margin-top: 56px;
      }
      .time{
        font-size: 28px;
        color: #ffffff;
        margin-top: 8px;
      }
      .need{
        font-size: 24px;
        color: #ffffff;
        margin-top: 8px;
      }
    }
  }
  .seckill-box {
    .left-banner {
      width: 160px;
      background-color: #EF001C;
      height: 210px;
      padding: 30px 20px;
      text-align: center;
      color: #ffffff;

      .active-name {
        font-size: 36px;
      }

      .active-eng {
        font-size: 20px;
        color: #dddddd;
        margin-bottom: 40px;
      }

      .time-tip {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }

    .right-goods-list {
      background-color: #ffffff;

      .seckill-goods-item {
        padding: 20px;
        width: 159px;
        border-left: 1px solid #dddddd;
        cursor: pointer;

        img {
          width: 150px;
          height: 156px;
          margin: 0 auto;
          display: block;
          transition: 1s;
        }

        .img:hover {
          transform: scale(1.1);
        }

        .seckill-goods-name {
          margin: 10px 0;
        }

        .seckill-goods-price {
          span {
            display: inline-block;
            width: 67px;
            border: 1px solid #EF001C;
            padding: 0 5px;
            height: 24px;
            line-height: 24px;
            vertical-align: bottom;
          }

          .seckill-price {
            background-color: #EF001C;
            color: #ffffff;
            font-weight: bold;
            font-size: 16px;
          }

          .price {
            font-size: 14px;
            color: #999999;
            text-decoration: line-through;
          }
        }
      }

      seckill-goods-item:first-child {
        border-left: none;
      }
    }
  }

  .right-fix-nav {
    position: fixed;
    right: 0px;
    top: 50%;
    margin-top: -180px;
    width: 40px;
    height: 360px;
    background-color: #ffffff;
    z-index: 1000;
    padding: 0 10px 10px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, .3);

    .nav-item {
      width: 40px;
      font-size: 14px;
      color: #999999;
      margin-bottom: 10px;
      border-top: 1px solid #dddddd;
      padding-top: 10px;
      cursor: pointer;

      .text {
        text-align: center;
      }

      i {
        font-size: 20px;
        width: 20px;
        height: 20px;
        display: block;
        margin: 0 auto;

      }
    }

    .nav-item:hover {
      color: #EF001C;
    }

    .nav-item:first-child {
      border-top: none;
    }
  }

  .banner-info {
    .quick-menu {
      width: 232px;
      height: 510px;
      position: absolute;
      background-color: #ffffff;

      .bg-main-color {
        position: absolute;
        z-index: 1000;
        top: -40px;
        text-align: center;
        line-height: 40px;
      }

      .menu-list {
        .first-menu-list {
          position: absolute;
          width: 232px;
          height: 510px;
          background-color: #ffffff;
          z-index: 99;
          overflow-y: auto;

          .first-menu-item {
            margin-top: 18px;

            .name {
              cursor: pointer;
              font-size: 16px;
              line-height: 30px;
              padding-left: 20px;
              font-weight: bold;
            }

            .first-menu-second-menu {
              overflow: hidden;
              padding-left: 20px;
              height: 26px;

              .first-second-menu-list {

                .first-second-menu-item {
                  font-size: 12px;
                  color: #666666;
                  margin-right: 5px;
                  cursor: pointer;
                }
              }
            }
          }
        }

        .second-menu-list {
          display: none;
          position: absolute;
          width: 928px;
          height: 470px;
          left: 232px;
          background-color: #FFFFFF;
          z-index: 99;
          overflow-y: auto;
          box-shadow: 0 0 3px 2px rgba(0, 0, 0, .2);
          padding: 20px;

          .right-menu-item {
            display: flex;
            margin-bottom: 20px;

            .second-menu-title {
              font-size: 14px;
              color: #333333;
              margin-right: 20px;
              width: 80px;
              text-align: right;
              cursor: pointer;
            }

            .third-menu-list {
              display: flex;
              border-bottom: 1px #dddddd dashed;
              padding-bottom: 5px;
              flex-flow: wrap;
              width: 800px;

              .third-menu-item {
                cursor: pointer;
                margin-bottom: 5px;
                font-size: 12px;
                color: #666666;
                padding: 0 10px;
                border-left: 1px #dddddd solid;
                border-right: 1px #dddddd solid;
              }
            }
          }
        }
      }

      .menu-list:hover {
        .second-menu-list {
          display: block;
        }
      }
    }

    .user-states {
      .user-img {
        width: 75px;
        height: 75px;
        border-radius: 50%;

        .img {
          width: 70px;
          height: 70px;
          border-radius: 50%;
        }
      }
    }

    .entrance-item {
      width: 32.5%;
      height: 100px;
      border-width: 0 1px 1px 0;
      border-color: #f1f1f1;
      border-style: solid;

      .iconfont {
        font-size: 35px;
      }
    }

  }

  .left-img {
    width: 197px;
    height: 360px;
    border-width: 0 1px 1px 0;
    border-color: #f8f8f8;
    border-style: solid;
  }

  .category-sun-border {
    width: 196px;
    height: 178px;
    border-width: 0 1px 1px 0;
    border-color: #f8f8f8;
    border-style: solid;
  }

  .category-sun-img {
    transition: 1s;
    width: 110px;
    height: 110px;
    cursor: pointer;
  }

  .category-sun-img:hover {
    transform: scale(1.1);
  }

  .category-sun-tree {
    width: 33.33%;
    height: 120px;
    border-width: 0 1px 1px 0;
    border-color: #f8f8f8;
    border-style: solid;
  }

  .category-sun-tree-img {
    width: 80px;
    height: 80px;

    .category-sun-img {
      width: 60px;
      height: 60px;
    }

    .category-sun-img:hover {
      width: 65px;
      height: 65px;
    }
  }

  .goods-item {
    border: 1px solid rgba(240, 240, 240, .5);
    width: 198px;
    height: 258px;
  }

  .guessyou-line {
    border-bottom: 2px dashed #C6C6C6;
  }


</style>
