export default {
  goodsList: [{
      name: "正品直邮Hermes爱马仕2017新款男鞋 时尚真皮休闲鞋H171325ZH02 7495",
      images: "http://new.chunfengbuy.com/images/201703/thumb_img/0_thumb_G_1490915806032.jpg",
      price: 38
    },
    {
      name: "正品直邮Hermes爱马仕2017新款男鞋 时尚真皮休闲鞋H171325ZH02 7495",
      images: "http://new.chunfengbuy.com/images/201703/thumb_img/0_thumb_G_1490159924227.jpg",
      price: 39
    },
    {
      name: "正品直邮Hermes爱马仕2017新款男鞋 时尚真皮休闲鞋H171325ZH02 7495",
      images: "http://new.chunfengbuy.com/images/201703/thumb_img/0_thumb_G_1490223850447.jpg",
      price: 393.6
    },
    {
      name: "正品直邮Hermes爱马仕2017新款男鞋 时尚真皮休闲鞋H171325ZH02 7495",
      images: "http://new.chunfengbuy.com/images/201703/thumb_img/0_thumb_G_1490218905472.jpg",
      price: 3978.2
    },
    {
      name: "正品直邮Hermes爱马仕2017新款男鞋 时尚真皮休闲鞋H171325ZH02 7495",
      images: "http://new.chunfengbuy.com/images/201703/thumb_img/0_thumb_G_1490174925807.jpg",
      price: 139.2
    },
    {
      name: "正品直邮Hermes爱马仕2017新款男鞋 时尚真皮休闲鞋H171325ZH02 7495",
      images: "http://new.chunfengbuy.com/images/201703/thumb_img/0_thumb_G_1490159762992.jpg",
      price: 219.9
    },
    {
      name: "正品直邮Hermes爱马仕2017新款男鞋 时尚真皮休闲鞋H171325ZH02 7495",
      images: "http://new.chunfengbuy.com/images/201703/thumb_img/0_thumb_G_1490218667022.jpg",
      price: 1371.8
    },
    {
      name: "正品直邮Hermes爱马仕2017新款男鞋 时尚真皮休闲鞋H171325ZH02 7495",
      images: "http://new.chunfengbuy.com/images/201703/thumb_img/0_thumb_G_1490208869148.jpg",
      price: 217.2
    },
    {
      name: "正品直邮Hermes爱马仕2017新款男鞋 时尚真皮休闲鞋H171325ZH02 7495",
      images: "http://new.chunfengbuy.com/images/201703/thumb_img/0_thumb_G_1490224029400.jpg",
      price: 687.8
    },
    {
      name: "正品直邮Hermes爱马仕2017新款男鞋 时尚真皮休闲鞋H171325ZH02 7495",
      images: "http://new.chunfengbuy.com/images/201703/thumb_img/0_thumb_G_1490218873837.jpg",
      price: 216.1
    }
  ],
  recommendList: [{
      bgImg: "http://new.chunfengbuy.com/data/gallery_album/2/original_img/1494984987302153402.jpg",
      name: "新品推荐E",
      info: "专注生活美学",
      img: "http://new.chunfengbuy.com/data/gallery_album/2/original_img/1494985002375136884.png",
      url: ""
    },
    {
      bgImg: "http://new.chunfengbuy.com/data/gallery_album/2/original_img/1494984989930757668.jpg",
      name: "新品推荐D",
      info: "专注生活美学",
      img: "http://new.chunfengbuy.com/data/gallery_album/2/original_img/1494984989766362152.png",
      url: ""
    },
    {
      bgImg: "http://new.chunfengbuy.com/data/gallery_album/2/original_img/1494984989391013089.jpg",
      name: "新品推荐A",
      info: "专注生活美学",
      img: "http://new.chunfengbuy.com/data/gallery_album/2/original_img/1494984990383161028.png",
      url: ""
    },
    {
      bgImg: "http://new.chunfengbuy.com/data/gallery_album/2/original_img/1494984987606903394.jpg",
      name: "新品推荐B",
      info: "专注生活美学",
      img: "http://new.chunfengbuy.com/data/gallery_album/2/original_img/1494984988032635434.png",
      url: ""
    },
    {
      bgImg: "http://new.chunfengbuy.com/data/gallery_album/2/original_img/1494984990175755536.jpg",
      name: "新品推荐C",
      info: "专注生活美学",
      img: "http://new.chunfengbuy.com/data/gallery_album/2/original_img/1494984991251825734.png",
      url: ""
    }
  ],
  brandList: [
    {
      brandimg: "http://new.chunfengbuy.com/data/brandlogo/1490073900838296364.jpg"
    },
    {
      brandimg: "http://new.chunfengbuy.com/data/brandlogo/1490075385239594909.jpg"
    },
    {
      brandimg: "http://new.chunfengbuy.com/data/brandlogo/1490072850306019115.jpg"
    },
    {
      brandimg: "http://new.chunfengbuy.com/data/brandlogo/1490039286075654490.jpg"
    },
    {
      brandimg: "http://new.chunfengbuy.com/data/brandlogo/1490073900838296364.jpg"
    },
    {
      brandimg: "http://new.chunfengbuy.com/data/brandlogo/1490072373278367315.jpg"
    },
    {
      brandimg: "http://new.chunfengbuy.com/data/brandlogo/1490072677495061584.jpg"
    },
    {
      brandimg: "http://new.chunfengbuy.com/data/brandlogo/1490072694695600078.jpg"
    },
    {
      brandimg: "http://new.chunfengbuy.com/data/brandlogo/1490072756032175204.jpg"
    },
    {
      brandimg: "http://new.chunfengbuy.com/data/brandlogo/1490074043963552715.jpg"
    }
  ]
}
