import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index'
import HomeIndex from '../views/home/Index'
import Logo from '../views/public/Logo'
import UserCenter from '../views/UserCenter'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', redirect: '/index'
  },
  {
    path: '/index', name: 'Index', component: Index, redirect: '/homeIndex',
    children: [
      { path: '/homeIndex', name: 'HomeIndex',component: HomeIndex },
      { path: '/category', name: 'Category', component: () => import('../views/category/Category')},
      { path: '/newGoods', name: 'NewGoods',component: () => import('../views/goodsList/NewGoods')},
      { path: '/hotGoods', name: 'HotGoods',component: () => import('../views/goodsList/HotGoods') },
      { path: '/recommendGoods', name: 'RecommendGoods',component: () => import('../views/goodsList/RecommendGoods') },
      { path: '/product', name: 'Product', component: () => import('../views/product/Product') },
      { path: '/searchGoods', name: 'SearchGoods', component: () => import('../views/searchGoods/SearchGoods') },
      { path: '/cart', name: 'Cart', component: () => import('../views/cart/Cart') },
      { path: '/editOrderInfo', name: 'EditOrderInfo', component: () => import('../views/pay/EditOrderInfo') },
      { path: '/pay', name: 'Pay', component: () => import('../views/pay/Pay') },
      { path: '/seckillList', name: 'SeckillList', component: () => import('../views/seckillList/SeckillList') },
      { path: '/distributionIndex', name: 'DistributionIndex', component: () => import('../views/distribution/DistributionIndex') },
      { path: '/receiveCoupon', name: 'ReceiveCoupon', component: () => import('../views/coupon/ReceiveCoupon') },
      { path: '/brandList', name: 'BrandList', component: () => import('../views/brand/BrandList') },
      { path: '/brandGoods', name: 'BrandGoods', component: () => import('../views/brand/BrandGoods') },
      { path: '/boostCenter', name: 'BoostCenter', component: () => import('../views/boost/BoostCenter') },
      { path: '/boostLaunchDetail', name: 'BoostLaunchDetail', component: () => import('../views/boost/BoostLaunchDetail') },
    ],
  },
  { path: '/login', name: 'Logo', component: Logo },
  {
    path: '/userCenter', name: 'UserCenter', component: UserCenter, redirect: '/userCenter/order',
    children: [
      { path: '/userCenter/order', name: 'Order', component: () => import('../views/order/Order') },
      { path: '/userCenter/establishOrder', name: 'EstablishOrder', component: () => import('../views/order/EstablishOrder') },
      { path: '/userCenter/address', name: 'UserAddress', component: () => import('../views/userCenter/Address') },
      { path: '/userCenter/userInfo', name: 'UserInfo', component: () => import('../views/userCenter/UserInfo') },
      { path: '/distribution/distributionOrders', name: 'DistributionOrders', component: () => import('../views/distribution/DistributionOrders') },
      { path: '/distribution/choiceDistributionGoods', name: 'ChoiceDistributionGoods', component: () => import('../views/distribution/ChoiceDistributionGoods') },
      { path: '/distribution/profitDetails', name: 'ProfitDetails', component: () => import('../views/distribution/ProfitDetails') },
      { path: '/distribution/applyProfit', name: 'ApplyProfit', component: () => import('../views/distribution/ApplyProfit') },
      { path: '/distribution/withdrawalList', name: 'WithdrawalList', component: () => import('../views/distribution/WithdrawalList') },
      { path: '/distribution/myDistributionInfo', name: 'MyDistributionInfo', component: () => import('../views/distribution/MyDistributionInfo') },
      { path: '/distribution/applyDistribution', name: 'ApplyDistribution', component: () => import('../views/distribution/ApplyDistribution') },
      { path: '/distribution/distributionStatus', name: 'DistributionStatus', component: () => import('../views/distribution/DistributionStatus') },
      { path: '/distribution/mySubordinate', name: 'MySubordinate', component: () => import('../views/distribution/MySubordinate') },
      { path: '/coupon/myCoupon', name: 'MyCoupon', component: () => import('../views/coupon/MyCoupon') },
      { path: '/userCenter/myRedEnvelopeList', name: 'MyRedEnvelopeList', component: () => import('../views/userCenter/MyRedEnvelopeList') },
      { path: '/userCenter/MyBoost', name: 'MyBoost', component: () => import('../views/boost/MyBoost') },
    ],
  },
  {
    path: '*', redirect: '/index'
  },
]
const router = new VueRouter({
  scrollBehavior (to, from, saveTop) {
    if (saveTop) {
      return saveTop
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})
// 挂载路由导航守卫
/*router.beforeEach((to, from, next) => {
  // to 将要访问的路径     from代表从哪个路径跳转而来
  // next是一个函数，代表放行 next()放行   next('/login') 强制跳转
  // 如果访问的是不是需要token的页面，直接放行
  if (to.path == '/login') return next()
  if (to.path == '/index') return next()
  if (to.path == '/category') return next()
  if (to.path == '/product') return next()
  // 获取token
  const tokenStr = window.localStorage.getItem('token')
  // 如果token不存在，那么跳转到登录页
  if (!tokenStr) {
    return next('/logo')
  }
  // 如果存在，直接放行
  next()
})*/

export default router
