<template>
  <div>
    <!-- 公共头 -->
    <common-header></common-header>
    <div class="login-center mt-2 py-9">
      <div class="w-1200 d-f">
        <el-image :src="bannerUrl" fit="fill"></el-image>
        <div class="right-login-form">
          <div class="quick-login-box">
            <div class="tit">
              <h3>用第三方账号直接登录</h3>
              <span></span>
            </div>
            <div class="quick-login d-f">
              <div class="quick-item">
                <i class="wechat-icon"></i>
              </div>
            </div>
            <div class="tit">
              <h3>账号登录</h3>
              <span></span>
            </div>
          </div>
          <el-form class="mt-2" :model="loginForm" :rules="loginRules" ref="loginFormRef"
                   label-width="0px">
            <el-form-item label="" prop="mobile">
              <el-input
                placeholder="请输入手机号"
                prefix-icon="el-icon-phone-outline"
                v-model="loginForm.mobile">
              </el-input>
            </el-form-item>
            <el-form-item label="" prop="mobile">
              <el-row :gutter="10">
                <el-col :span="16">
                  <el-input
                    placeholder="验证码"
                    prefix-icon="el-icon-lock"
                    v-model="loginForm.verify_code">
                  </el-input>
                </el-col>
                <el-col :span="8">
                  <el-button class="w-100" v-if="codeTime>0" disabled type="danger">{{codeTime}}</el-button>
                  <el-button class="w-100" v-else type="danger" @click="sendVerifyCode">验证码</el-button>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-button class="w-100" type="primary" @click="loginSubmit">登录</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <!-- 公共低部 -->
    <common-footer></common-footer>
  </div>
</template>

<script>
import commonHeader from '@/components/common/common-header.vue'
import commonFooter from '@/components/common/common-footer.vue'
import { sendSms as sendSmsUrl, getLogin as getLoginUrl,userInfo as getUserInfoUrl } from '@/api/index'

export default {
  components: {
    commonHeader,
    commonFooter,
  },
  data () {
    return {
      bannerUrl: 'https://img1.360buyimg.com/pop/jfs/t1/184312/21/5986/101931/60ade255Eb7f7a3eb/0c4307ab36ee8690.jpg',
      loginForm: {
        mobile: '',
        verify_code: ''
      },
      loginRules: {},
      codeTime: 0,
    }
  },
  created () {
    this.initData()
  },
  methods: {
    initData(){
      this.$message.error('请先登录')
    },
    //手机表单验证
    validata () {
      //手机号正则
      var mPattern = /^1[34578]\d{9}$/
      //输出 true
      if (!mPattern.test(this.loginForm.mobile)) {
        this.$message.error('手机号码错误')
        return false
      }
      return true
    },
    // 发送验证码
    async sendVerifyCode () {
      if (!this.validata()) return false
      const { data: res } = await this.$http.get(sendSmsUrl, { params: { mobile: this.loginForm.mobile } })
      if (res.code !== 200) return this.$message.error(res.msg)
      this.$message.success('验证码已发送')
      //倒计时
      this.codeTime = 60
      let timer = setInterval(() => {
        if (this.codeTime >= 1) {
          this.codeTime--
        } else {
          this.codeTime = 0
          clearInterval(timer)
        }
      }, 1000)
    },
    // 登录
    async loginSubmit () {
      if (!this.validata()) return false
      // 登录
      const { data: res } = await this.$http.post(getLoginUrl, this.loginForm)
      if (res.code !== 200) return this.$message.error(res.msg)
      window.localStorage.setItem('token', res.data)
      this.$message.success('登录成功')
      // 保存用户信息
      const { data: userInfoRes } = await this.$http.get(getUserInfoUrl)
      window.sessionStorage.setItem('userInfo', JSON.stringify(userInfoRes.data))
      console.log(userInfoRes)
      this.$store.commit('changeLoginStatusMutations',true)
      this.$router.go(-1);
    },

  }
}
</script>

<style lang="less">
  .login-center {
    background-color: #F8DCDB;
    width: 100%;
    height: 465px;

    .right-login-form {
      background-color: #ffffff;
      padding: 40px 20px;
      width: 330px;
      height: 300px;
      .quick-login-box {
        .quick-login {
          justify-content: center;

          .quick-item {
            margin: 15px 10px;

            i {
              cursor: pointer;
            }

            .wechat-icon {
              display: block;
              width: 36px;
              height: 36px;
              background-image: url(../../assets/img/login_icon.png);
              background-repeat: no-repeat;
              background-position: 0px -37px;
            }

            .wechat-icon:hover {
              background-position: -39px -37px;
            }
          }
        }
      }

      .tit {
        height: 20px;
        line-height: 20px;
        text-align: center;
        position: relative;

        h3 {
          font-size: 16px;
          color: #8c8c8c;
          padding: 0 15px;
          display: inline-block;
          position: relative;
          z-index: 2;
          background-color: #fff;
        }

        span {
          position: absolute;
          z-index: 1;
          left: 0;
          right: 0;
          top: 9px;
          height: 1px;
          border-bottom: 1px dashed #e5e5e5;
        }
      }
    }
  }
</style>
